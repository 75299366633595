import React from 'react';

function Button(props) {
    return (
        <button className="w-64 sm:w-48 lg:w-56 h-12 bg-vermilion-red text-white xl:hover:bg-black">
            <a href={props.link.toString()}>{props.text}</a>
        </button>
    )
}

export default Button
