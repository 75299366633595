import React from 'react';
import PortfolioItem from "./PortfolioItem.js";
import projectsPhotos from "./projects.js"


function Portfolio(props) {

    const projects = props.projects.map((propsItem, index) => (
            {
                id: propsItem.project.id,
                image: projectsPhotos[index],
                alt: propsItem.project.header + ". " + propsItem.project.paragraph,
                header: propsItem.project.header,
                paragraph: propsItem.project.paragraph

            }
        )             
    )

    return (
        <div>
            <h1 className="font-bold text-3xl mt-16"> Portfolio </h1>
            <div className=" sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5">
                {
                    projects.map((project) => 
                    <PortfolioItem key={project.id}
                                   image={project.image}
                                   alt={project.alt}
                                   header={project.header}
                                   paragraph={project.paragraph}/>)
                }
            </div>
        </div>
    )
}

export default Portfolio
