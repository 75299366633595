import React from 'react';
import {
    Link
  } from "react-router-dom";
import { ReactComponent as FooterPic } from './footer_pic.svg';
import { ReactComponent as SmailiLogo } from '../Header/smaili_logo.svg';
import Button from '../Button'

function Footer(props) {
    return (
        <div className="mt-16 sm:absolute sm:inset-x-0 sm:bottom-0 2xl:mt-32">
            <footer className="bg-whisper-grey h-120 sm:relative sm:h-68 sm:flex md:justify-between xl:h-76 3xl:92">
                <div className="px-16 pt-6 mb-2 sm:px-4 sm:pt-8 lg:px-16 xl:w-full xl:flex xl:justify-between xl:pt-16">
                    <div className="px-4 sm:px-0 xl:pl-24">
                        <div className="pl-2">
                            <Link to="/"><SmailiLogo/></Link>
                        </div>
                        <p className="h-12 w-56 pr-4 mb-4 text-center sm:order-last xl:mt-8"> 
                            +48 690 126 336 <br/>
                            <a href="mailto:info@smaili.pl">info@smaili.pl </a>
                        </p>
                        {props.language === "en" ? (
                                                <div className="text-vermilion-red pl-10 mb-4 text-xl">
                                                    <Link to="/cookies-policy">{props.cookiesPolicyLink}</Link>
                                                </div>
                                            ) : (
                                                <div className="text-vermilion-red pl-8 mb-4 text-base xl:text-xl xl:pl-4">
                                                    <Link to="/cookies-policy">{props.cookiesPolicyLink}</Link>
                                                </div>
                            )}

                    </div>
                    <div className="3xl:mr-44 4xl:mr-40">
                        <Button text={props.buttonText}
                                link="mailto:info@smaili.pl"/>
                    </div>
                </div>
                <div className="mt-10 right-0 bottom-0 sm:absolute md:w-132 md:h-76 xl:h-108 xl:w-152 xl:mr-64 xl:pt-24 3xl:mr-108 3xl:pt-16 3xl:w-192 4xl:mr-100">
                    <FooterPic/>
                </div>
            </footer>
            
        </div>   
    )
}

export default Footer
