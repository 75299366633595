import React from 'react'
import {
    Link
  } from "react-router-dom";
import cookie from './cookie.svg';


function CookiesBanner(props) {
    
    if(!props.showBanner) {
        return null
    } else {

    return (


            <div className="fixed bottom-0 flex h-36 w-11/12 bg-white py-4 border-2 my-2 mx-4 sm:flex sm:h-32 sm:mx-6 md:mx-10 lg:px-16 lg:py-6 xl:px-48 xl:py-2 2xl:mx-16 ">
                <div className="w-24 h-24 pt-2 pl-4 xl:pr-2">
                    <img className="w-16 h-16 xl:w-20 xl:h-20" src={cookie} alt="cookie"/>
                </div>
                
                    {props.language === 'ru' ? (
                        <div className="w-68 text-justify text-sm sm:flex sm:w-full sm:justify-between sm:text-base">
                            <div className="pr-2 sm:pr-0 sm:pl-2 sm:mr-2 sm:pt-2 sm:w-2/3 xl:pt-4">
                                {props.content.greetings}
                            </div>
                            <div className="flex mt-2 sm:flex-row-reverse sm:w-64">
                                <button className="w-24 py-1 bg-vermilion-red text-white sm:h-10 sm:mt-2 sm:mr-2 xl:hover:bg-black xl:px-2 " onClick={() => props.onClick()}>{props.content.agreeButton}</button>
                                <div className="w-40 text-vermilion-red pl-2 pt-1 sm:w-44 sm:pt-4 3xl:mr-4">
                                     <Link to="/cookies-policy"> {props.cookiesPolicyLink}</Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-68 text-justify px-2 text-sm sm:flex sm:justify-between sm:w-full sm:text-base ">
                            <div className="pt-2 sm:w-2/3 sm:mr-2 xl:pt-6">
                                {props.content.greetings}
                            </div>
                            <div className="flex mt-2 sm:flex-row-reverse sm:h-16 sm:w-64 sm:mt-6">
                                <button className="w-20 bg-vermilion-red text-white py-1 sm:h-10 xl:hover:bg-black " onClick={() => props.onClick()}>{props.content.agreeButton}</button>
                                <div className="w-40 text-vermilion-red pl-2 pt-1 text-sm sm:text-base sm:w-44 lg:mr-4 ">
                                    <Link to="/cookies-policy"> {props.cookiesPolicyLink}</Link>
                                </div>
                            </div>
                        </div>
                    )}  
            </div>
        )
    }
}

export default CookiesBanner
