import project1 from './assets/photos/project_1.jpg'
import project2 from './assets/photos/project_2.jpg'
import project3 from './assets/photos/project_3.jpg'
import project4 from './assets/photos/project_4.jpg'
import project5 from './assets/photos/project_5.jpg'
import project6 from './assets/photos/project_6.jpg'
import project7 from './assets/photos/project_7.jpg'
import project8 from './assets/photos/project_8.jpg'
import project9 from './assets/photos/project_9.jpg'
import project10 from './assets/photos/project_10.jpg'
import project11 from './assets/photos/project_11.jpg'
import project12 from './assets/photos/project_12.jpg'
import project13 from './assets/photos/project_13.jpg'
import project14 from './assets/photos/project_14.jpg'

export default [project1, project2, project3, project4, project5, project6, project7, project8, project9, project10, project11, project12, project13, project14]