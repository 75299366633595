import React from 'react';


function MenuItem(props) {
    return (
        <div className="w-64 h-92 mt-10 pl-4 pr-4 py-8 border-l-4 border-vermilion-red menu-item">
            {props.image}
            <h1 className="pt-10 pb-4 pb font-bold text-2xl 2xl:text-3xl"> {props.header} </h1>
            <p className="xl:w-48">{props.paragraph}</p>
        </div>
    )
}

export default MenuItem
