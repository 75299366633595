export default {

        button: "СДЕЛАТЬ ЗАКАЗ",
        cookiesPolicyLink: "Политика Cookie",
        body: {
            headerBodyOne : "Графический дизайн, реклама, переводы, мероприятия",
            paragraphBody: "Мы работаем на польском, английском и русском",
            headerBodyTwo: "Помогаем иностранным компаниям продвигать идеи и товары на польском рынке.",
        },
        menu: [
            {
                menuItem: {
    
                    id: "1",
                    image:"",
                    header: "Дизайн и производство",
                    paragraph: "Брошюры, каталоги, листовки, меню"
        
                }
            },
            {
                menuItem: {
    
                    id: "2",
                    image:"",
                    header: "Web-проекты",
                    paragraph: "Веб-сайты, лендинги, поисковая и медийная реклама, seo-оптимизация"
                    
                }
            },
            {
                menuItem: {
    
                    id: "3",
                    image:"",
                    header: "Локализация и перевод",
                    paragraph: "Веб-сайты, игры, каталоги, брошюры, рекламные макеты"
                    
                },
            }, 
            {
                menuItem: {
    
                    id: "4",
                    image:"",
                    header: "Special events",
                    paragraph: "Вечеринки, конференции, мастер-классы"
                    
                }
            }   
        ],

        projects: [
            {
                project: {
                    id: "1",
                    image: '',
                    header: "Кафе Byblos",
                    paragraph: "Оклейка витрин"
                }   
            },
            {
                project: {
                    id: "2",
                    image: '',
                    header: "Кафе Byblos",
                    paragraph: "Оклейка витрин"
                }   
            },
            {
                project: {
                    id: "3",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Оформление выставки"
                }   
            },
            {
                project: {
                    id: "4",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Сувенирная продукция"
                }   
            },
            {
                project: {
                    id: "5",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Сувенирная продукция"
                }   
            },
            {
                project: {
                    id: "6",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Оформление выставки"
                }   
            },
            {
                project: {
                    id: "7",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Лифлет: адаптация на польский язык и печать"
                }   
            },
            {
                project: {
                    id: "8",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Разработка и дизайн корпоративной презентации"
                }   
            },
            {
                project: {
                    id: "9",
                    image: '',
                    header: "Кафе Byblos",
                    paragraph: "Дизайн меню-борда"
                }   
            },
            {
                project: {
                    id: "10",
                    image: '',
                    header: "Кафе Byblos",
                    paragraph: "Корпоративный стиль и дизайн меню"
                }   
            },
            {
                project: {
                    id: "11",
                    image: '',
                    header: "Кафе Byblos",
                    paragraph: "Поддержка в соцсетях"
                }   
            },
            {
                project: {
                    id: "12",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Брошюра: адаптация на польский язык и печать"
                }   
            },
            {
                project: {
                    id: "13",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Каталог: адаптация на польский язык и печать"
                }   
            },
            {
                project: {
                    id: "14",
                    image: '',
                    header: "Jaquar Group",
                    paragraph: "Дизайн плакатов"
                }   
            }
        ],
        cookiesBannerText: {
            greetings: "Приветствуем! Мы используем файлы cookies, поскольку хотим обеспечить максимальный уровень сервиса.",
            agreeButton: "Принимаю"
        }, 

        cookiesPolicy: {
            privacyPolicy: {
                pageHeader: "Политика в отношении обработки персональных данных",
                header: "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ",
                policyItems: [
                    {
                        id: "1",
                        content: "Настоящая политика обработки персональных данных составлена в соответствии с требованиями Общего регламента защиты персональных данных в ЕС № 2016/679 GDPR (General Data Protection Regulation) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных после регистрации на сайте и в связи с использованием его функциональности, предпринимаемые Daria El-Smaili (далее – Администратор), по адресу Franciszka Klimczaka 17/80, 02-797 Варшава, Польша и личным идентификационным номером налогоплательщика (NIP) 9512486306"
                    },
                    {
                        id: "2",
                        content: "Ваши личные данные будут обрабатываться Администратором, в частности, для следующих целей: отправки рассылки и сообщений, содержащих коммерческую информацию, возможности связаться с Администратором через контактные формы, защиты от возможных претензий в аналитической и статистической формах."
                    },
                    {
                        id: "3",
                        content: "Данные будут предоставляться структурам, обрабатывающим их от имени и на основании соглашений с Администратором, но только с целью и в объеме, необходимом для достижения вышеуказанных целей, включая организации, предоставляющие IT-услуги или другие услуги, обеспечивающие надлежащее функционирование веб-сайта www.smaili.pl, где такие организации обрабатывают данные только в соответствии с инструкциями Администратора."
                    },
                    {
                        id: "4",
                        content: "Помимо этих организаций, ваши данные могут быть предоставлены только организациям, уполномоченным на это в соответствии с государственным законодательством или законодательством ЕС. Администратор сайта приложил все усилия, чтобы обезопасить предоставленные персональные данные и не допустить их разглашения посторонним лицам."
                    },
                    {
                        id: "5",
                        content: "Ваши личные данные будут обрабатываться только в течение времени, необходимого для достижения цели обработки или до тех пор, пока Ваше согласие не будет отозвано. Администратор оставляет за собой право обработки персональных данных в течение периода до 3 месяцев с момента достижения целей, связанных с выполнением собственных аналитических и статистических задач."
                    },
                    {
                        id: "6",
                        description: "Персональные данные разрешены к обработке в рамках настоящей Политики конфиденциальности на основе:",
                        content: [
                            {
                                id: "a",
                                text: "согласия (статья 6 (1) RODO), в маркетинговых целях"
                            },
                            {
                                id: "b",
                                text: "b.	соблюдения обязательства как по государственному законодательству, так и по законодательству ЕС в соответствии со статьей 6 пункт 1 в RODO"
                            },
                            {
                                id: "c",
                                text: "c.	для целей, вытекающих из законных интересов Администратора, включая предоставление ответов на вопросы, отправленные через контактную форму, а также для защиты Администратора от любых претензий третьих лиц"
                            }
                        ]
                    },
                    {
                        id: "7",
                        content: "В любое время существует возможность отозвать согласие, выраженное на сайте, в том числе согласие на обработку данных в маркетинговых целях субъектов, сотрудничающих с Администратором. Отмена согласия на обработку не повлияет на законность обработки, которая была осуществлена до ее отмены."
                    },
                    {
                        id: "8",
                        content: "Вы имеете право запросить доступ, исправить, стереть, перенести или ограничить обработку данных, а также право на возражение против обработки. Если вы хотите воспользоваться своими правами, пожалуйста, отправьте нам сообщение по адресу info@smaili.pl."
                    },
                    {
                        id: "9",
                        content: "Безопасность персональных данных является для нас приоритетом, однако, если вы считаете, что при обработке ваших персональных данных мы нарушаем правила RODO, вы имеете право подать жалобу в Управление по защите персональных данных."
                    }
                ]
            },
            cookiesPolicy: {
                header: "ПОЛИТИКА COOKIE",
                description: "Настоящий веб-сайт использует файлы сookies, которые позволяют «запоминать» ваши действия на сайте, а также дают информацию о вашем взаимодействии с нашим контентом и помогают нам сделать посещение сайта более удобным для вас.",
                policyParagraphs: [
                    {
                        id: "1",
                        header: "§ 1 ОСНОВНЫЕ ПОНЯТИЯ",
                        paragraphItems: [
                            {
                                id: "1.0",
                                text: "Веб- сайт – веб-страница, расположенная в сети Интернет по адресу: https://smaili.pl/"
                            },
                            {
                                id: "1.1",
                                text: "Внешнее обслуживание - интернет-услуги партнеров, поставщиков услуг или получателей услуг Администратора"
                            },
                            {
                                id: "1.2",
                                text: "Администратор – Daria El-Smaili, офис по адресу: Franciszka Klimczaka 17/80, 02-797 Варшава, Польша, с личным идентификационным номером налогоплательщика (NIP): 9512486306, предоставление услуг в электронном виде через веб-сайт, а также хранение и доступ к информации на устройствах Пользователей."
                            },
                            {
                                id: "1.3",
                                text: "Пользователь - физическое лицо, для которого Администратор предоставляет услуги в электронном виде через веб-сайт https://smaili.pl/"
                            },
                            {
                                id: "1.4",
                                text: "Устройство - электронное устройство с программным обеспечением, с помощью которого Пользователь получает доступ к веб-сайту."
                            },
                            {
                                id: "1.5",
                                text: "Cookies - текстовые данные, собранные в виде файлов, размещенных на Устройстве Пользователя."
                            },
                        ]
                    },
                    {
                        id: "2",
                        header: "§ 2 ТИПЫ ФАЙЛОВ “COOKIES”",
                        paragraphItems: [
                            {
                                id: "2.1",
                                text: "Сессионные (временные) файлы cookie — они могут существовать только во временной памяти, пока вы находитесь на веб-ресурсе (браузер удаляет сессионные cookie после того, как вы закрыли его окно)"
                            },
                            {
                                id: "2.2",
                                text: "Постоянные файлы cookie— не удаляются автоматически после окончания сеанса работы устройства до тех пор, пока они не будут удалены вручную (они передаются на сервер каждый раз, как вы переходите на сайт)"
                            },
                            {
                                id: "2.3",
                                text: "Внутренние файлы cookie – позволяют узнавать, осуществляет ли навигацию человек или автоматизированное приложение, анонимный или зарегистрированный пользователь, а также собирают информацию о типе осуществляемой навигации, о секциях, которые чаще всего используются, о временнóм диапазоне использования, языке и т. п."
                            },
                            {
                                id: "2.4",
                                text: "Сторонние файлы cookie - это файлы cookie, которые отправляются на терминал пользователя с компьютера или домена, который управляется не издателем, а другой организацией, которая обрабатывает данные, полученные с помощью файлов cookie."
                            },
                        ]
                    },
                    {
                        id: "3",
                        header: "§ 3 БЕЗОПАСНОСТЬ",
                        paragraphItems: [
                            {
                                id: "3.1",
                                text: "Механизмы хранения и считывания - механизмы хранения и считывания файлов cookie не позволяют извлекать какие-либо личные данные или конфиденциальную информацию с вашего устройства. Передать вирусы, троянских коней и других червей на ваше устройство практически невозможно."
                            },
                            {
                                id: "3.2",
                                text: "Внутренние сookie-файлы - внутренние Cookie-файлы Администратора безопасны для  устройства пользователя."
                            },
                            {
                                id: "3.3",
                                text: "Сторонние cookie-файлы - Администратор не несет ответственности за безопасность cookie-файлов от партнеров сайта. Список партнеров представлен в следующей части Политики в отношении cookie-файлов."
                            },
                        ]
                    },
                    {
                        id: "4",
                        header: "§ 4 ЦЕЛИ, ДЛЯ КОТОРЫХ ИСПОЛЬЗУЮТСЯ ФАЙЛЫ COOKIES ",
                        paragraphItems:[
                            {
                                id: "4.1",
                                text: "Улучшение и упрощение доступа к сайту - Администратор вправе хранить в файлах cookie информацию о предпочтениях и настройках Пользователя в отношении сайта с целью улучшения, усовершенствования и ускорения предоставления услуг на сайте."
                            },
                            {
                                id: "4.2",
                                text: "Логин - Администратор использует cookie-файлы для регистрации Пользователей на сайте."
                            },
                            {
                                id: "4.3",
                                text: "Маркетинг и реклама - Администратор и внешние веб-сайты используют cookie-файлы в маркетинговых целях и для обслуживания рекламы Пользователей."
                            },
                            {
                                id: "4.4",
                                text: "Статистические данные - Администратор и внешние веб-сайты используют файлы cookie для сбора и обработки статистических данных, таких как, статистика посещений, статистика устройств пользователя или статистика поведения пользователей. Эти данные собираются с целью анализа и совершенствования веб-сайта."
                            },
                            {
                                id: "4.5",
                                text: "Обслуживание мультимедийных сервисов - Администратор и внешние сервисы используют файлы сookie для обслуживания Пользователей мультимедийных сервисов."
                            },
                            {
                                id: "4.6",
                                text: "Социальные услуги - Администратор и внешние сервисы используют cookie-файлы для поддержки социальных услуг."
                            },
                        ]
                    },
                    {
                        id: "5",
                        header: "§ 5 СТОРОННИЕ ВЕБ-САЙТЫ",
                        description: "Администратор сотрудничает со следующими сторонними веб-сайтами, которые могут размещать файлы cookie на устройствах пользователя:",
                        paragraphItems: [
                            {
                                id: "5.1",
                                text: "Google Analytics"
                            },
                            {
                                id: "5.2",
                                text: "Google AdSense"
                            },
                            {
                                id: "5.3",
                                text: "Google AdWords"
                            },
                            {
                                id: "5.4",
                                text: "Facebook"
                            },
                            {
                                id: "5.5",
                                text: "Youtube"
                            }
                        ]
                    },
                    {
                        id: "6",
                        header: "§ 6 ВОЗМОЖНОСТИ ОПРЕДЕЛЕНИЯ УСЛОВИЙ ХРАНЕНИЯ И ДОСТУПА НА ПОЛЬЗОВАТЕЛЬСКИХ УСТРОЙСТВАХ СЕРВИСНЫМИ И СТОРОННИМИ ВЕБ-САЙТАМИ",
                        paragraphItems: [
                            {
                                id: "6.1",
                                text: "Вы можете в любое время самостоятельно изменить настройки для сохранения, удаления и доступа к данным сохраненных cookie-файлов."
                            },
                            {
                                id: "6.2",
                                text: "Информация о том, как отключить cookies в наиболее популярных браузерах и мобильных устройствах, доступна по адресу: как отключить cookies https://www.aboutcookies.org/how-to-delete-cookies/."
                            },
                            {
                                id: "6.3",
                                text: "Пользователь может в любое время удалить любые файлы cookies, сохраненные до настоящего времени, используя инструменты устройства пользователя, с помощью которого пользователь использует услугу."
                            }
                        ]
                    },
                    {
                        id: "7",
                        header: "§ 7 ТРЕБОВАНИЯ К ВЕБ-САЙТУ",
                        paragraphItems: [
                            {
                                id: "7.1",
                                text: "Ограничение хранения и доступа к файлам cookie на Вашем устройстве может привести к сбоям в работе некоторых функций сайта."
                            },
                            {
                                id: "7.2",
                                text: "Администратор не несет никакой ответственности за неполадки в работе сайта в случае, если Пользователь каким-либо образом ограничивает возможность сохранения и чтения файлов cookies."
                            }
                        ]
                    },
                    {
                        id: "8",
                        header: "§ 8 ИЗМЕНЕНИЯ В ПОЛИТИКЕ В ОТНОШЕНИИ ФАЙЛОВ COOKIES",
                        paragraphItems: [
                            {
                                id: "8.1",
                                text: "Администратор оставляет за собой право изменять настоящую Политику в любое время без уведомления пользователей."
                            },
                            {
                                id: "8.2",
                                text: "Изменения, внесенные в Политику использования cookie файлов, всегда будут публиковаться на этой странице. "
                            },
                            {
                                id: "8.3",
                                text: "Внесенные изменения вступают в силу в день публикации Политики cookies."
                            }
                        ]
                    }
                ],
            }
        }
}
    