import React from 'react'

function CookiesPolicy(props) {
    return (
        <div className="mb-16">
            <h1 className="text-center text-4xl font-bold py-8 mt-8 lg:m-0 lg:pt-0"> {props.text.privacyPolicy.pageHeader}</h1>
            <h2 className="py-4 text-2xl font-medium "> {props.text.privacyPolicy.header}</h2>
            <ol className="list-decimal text-justify text-xl">
                {props.text.privacyPolicy.policyItems.map((policy) => 
                        <li key={policy.id} className="mb-4">
                            { Array.isArray(policy.content) ? (
                                <div>
                                    <p> {policy.description} </p>
                                    <ul>
                                        {policy.content.map((item) =>
                                            <li key={item.id}> {item.id}. {item.text} </li>
                                        )}
                                    </ul>
                                </div>


                            ) : (
                                policy.content
                            )
                        }
                        </li>
                )}
            </ol>

            <h2 className="py-4 text-2xl font-medium ">{props.text.cookiesPolicy.header}</h2>
            <p className="text-justify text-xl mb-2"> {props.text.cookiesPolicy.description} </p>
            <ul>
                {props.text.cookiesPolicy.policyParagraphs.map((paragraph) => 
                    <div key={paragraph.id}>
                        <h2 className="text-xl font-bold" >{paragraph.header}</h2>
                        { paragraph.description ? (
                            <p className="text-justify text-xl"> {paragraph.description} </p>
                        ): (
                            null
                        )}
                        <ul className="list-disc text-justify text-xl">
                            {paragraph.paragraphItems.map((paragraphItem) => 
                                <li key={paragraphItem.id}>
                                    {paragraphItem.text}
                                 </li>
                            )}
                        </ul>
                    </div>
                )}
            </ul>
        </div>
    )
}

export default CookiesPolicy
