import React from 'react';
import {
    Link
  } from "react-router-dom";
import { ReactComponent as SmailiLogo } from './smaili_logo.svg';
import Button from '../Button'

function Header(props) {

    let languages = props.languagesToDisplay

    return (

        <header className="pt-16 bg-white sm:flex sm:justify-between">
            <div className="flex mb-16 justify-between text-nobel-grey sm:block">
                <Link to="/"> <SmailiLogo/> </Link>
                <div className="w-16">
                    {languages.map( (language, key) => 
                            <button className="" key={key} data-language={language} onClick={(e) => props.onClick(e)}>{language.toUpperCase()} &rarr; </button>
                        )}
                </div>
            </div>

            <div className="pl-4 sm:flex-col sm:h-32 sm:pl-2 md:flex md:justify-end lg:flex-row">
                <p className="pl-2 h-12 w-56 my-4 text-center sm:w-48 sm:order-last lg:m-0"> 
                        +48 690 126 336 <br/>
                    <a href="mailto:info@smaili.pl">info@smaili.pl </a>
                </p>
                <Button text={props.buttonText}
                        link="mailto:info@smaili.pl"/>
            </div>
        </header>
    )
}

export default Header
