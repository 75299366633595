import React , { useState }  from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
  } from "react-router-dom";

import pl from './translation/pl'
import ru from './translation/ru'
import en from './translation/en'
import it from './translation/it'
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header';
import Body from './components/Body';
import Menu from './components/Body/Menu';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio'
import CookiesPolicy from './components/CookiesPolicy'
import CookieBanner from './components/CookiesBanner'

const languages = {
  en,
  ru,
  pl,
  it
}

const languagesInArray = [ "pl", "en", "ru", "it"]

function App() {

  const [language, setLanguage] = useState('pl')

  const [showBanner, setShowBanner] = useState(true) 

  const handleClick = (e) => {
    setLanguage(e.target.dataset.language)
  }

  const onClickHideBanner = () => setShowBanner(false)

  let languagesToDisplay = languagesInArray.filter(lang => lang !== language)

  let currentLanguageContent =  languages[language]

  return (
    <Router>
      <ScrollToTop/>
      <div className="sm:relative">
        
        <div className="px-12 sm:px-16 sm:pb-84 xl:px-44 xl:pb-100 2xl:px-56 2xl:pb-112 4xl:pb-108">
         <Header language={language}
                 languagesToDisplay={languagesToDisplay}
                 onClick={handleClick}
                 buttonText={currentLanguageContent.button}
                 />

        <Switch>
          <Route exact path="/">
                <Body content={currentLanguageContent.body}/>
                <Menu menu={currentLanguageContent.menu}/>
                <Portfolio projects={currentLanguageContent.projects}/>
          </Route>
          <Route path="/cookies-policy"> 
                <CookiesPolicy text={currentLanguageContent.cookiesPolicy}/>
          </Route>
        </Switch>  
        </div>
        
        <Footer language={language}
                cookiesPolicyLink={currentLanguageContent.cookiesPolicyLink}
                buttonText={currentLanguageContent.button}/> 

        <CookieBanner language={language} 
                      content={currentLanguageContent.cookiesBannerText}
                      cookiesPolicyLink={currentLanguageContent.cookiesPolicyLink}
                      showBanner ={showBanner}
                      onClick={onClickHideBanner}/>
       
      
      </div>
    </Router>
  );
}

export default App;
