import React from 'react';
import { ReactComponent as BodyPic } from './body_pic.svg';


function Body(props) {
    return (
        <div className="">
            <div className="py-10  sm:h-148">
                <div className="sm:w-1/2  2xl:w-1/2 2xl:pt-20">
                    <h1 className="font-bold text-3xl sm:mt-10 sm:w-1/2 md:h-84 lg:h-48  xl:w-full xl:mt-0 xl:text-4xl xl:break-normal">
                        {props.content.headerBodyOne}
                    </h1>
                    <p className="py-10 text-2xl  sm:w-full md:w-2/3 xl:mt-8 xl:py-2 xl:text-xl">
                        {props.content.paragraphBody}
                    </p>
                </div>
                <BodyPic/>
            </div>

            <h1 className="py-10 font-bold text-3xl text-align:left sm:block sm:w-3/4 xl:w-2/3 xl:mt-20 xl:text-4xl 2xl:w-156 3xl:w-164 4xl:w-1/2 4xl:mb-32">
                        {props.content.headerBodyTwo}
            </h1> 
        </div>
    )
}

export default Body
