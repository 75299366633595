import React from 'react';
import MenuItem from "./MenuItem.js";
import { ReactComponent as IconOne } from './assets/svg/icon1.svg';
import { ReactComponent as IconTwo } from './assets/svg/icon2.svg';
import { ReactComponent as IconThree } from './assets/svg/icon3.svg';
import { ReactComponent as IconFour } from './assets/svg/icon4.svg'


function Menu(props) {

    const svgComponents = [<IconOne/>, <IconTwo/>, <IconThree/>, <IconFour/>]

    const menuItemcContent = props.menu.map((propsItem, index) => (
            {
                id: propsItem.menuItem.id,
                image: svgComponents[index],
                header: propsItem.menuItem.header,
                paragraph: propsItem.menuItem.paragraph

            }
        )             
    )

    return (
        <div className="sm:grid sm:grid-cols-2 sm:gap-4 xl:grid-cols-4">
            {menuItemcContent.map((itemContent) => 
                <MenuItem key={itemContent.id}
                          image={itemContent.image}
                          header={itemContent.header}
                          paragraph={itemContent.paragraph}/>)

            }
        </div>
    )
}

export default Menu
