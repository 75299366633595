export default {

    button: "CONTATTACI",
    cookiesPolicyLink: "Cookie Policy",
    body: {
        headerBodyOne : "Graphic Design, pubblicità, traduzioni, eventi",
        paragraphBody: "Localizzazione e traduzione in Polacco, Inglese, Italiano e Russo",
        headerBodyTwo: "Supporto per promuovere le vostre idee, prodotti e business in Russia e Polonia",
    },
    menu: [
        {
            menuItem: {

                id: "1",
                image:"",
                header: "Design, grafica e stampa",
                paragraph: "Brochure, cataloghi, volantini, menù"
    
            }
        },
        {
            menuItem: {

                id: "2",
                image:"",
                header: "Progetti Web",
                paragraph: "Siti Web, landing page, SEO, SMM, SEM, Campagne SEA (Pubblicità e Marketing sui Motori di Ricerca)"
                
            }
        },
        {
            menuItem: {

                id: "3",
                image:"",
                header: "Localizzazione e traduzione",
                paragraph: "Siti Web, giochi, cataloghi, brochure, materiale pubblicitario, video"
                
            },
        }, 
        {
            menuItem: {

                id: "4",
                image:"",
                header: "Eventi speciali",
                paragraph: "Inaugurazioni, conferenze, workshop, party, progetti speciali"
                
            }
        }   
    ],

    projects: [
        {
            project: {
                id: "1",
                image: '',
                header: "Caffetteria Byblos",
                paragraph: "Adesivi per vetrine"
            }   
        },
        {
            project: {
                id: "2",
                image: '',
                header: "Caffetteria Byblos",
                paragraph: "Adesivi per vetrine"
            }   
        },
        {
            project: {
                id: "3",
                image: '',
                header: "Jaquar Group",
                paragraph: "Allestimento dello stand in fiera"
            }   
        },
        {
            project: {
                id: "4",
                image: '',
                header: "Jaquar Group",
                paragraph: "Gadget"
            }   
        },
        {
            project: {
                id: "5",
                image: '',
                header: "Jaquar Group",
                paragraph: "Gadget"
            }   
        },
        {
            project: {
                id: "6",
                image: '',
                header: "Jaquar Group",
                paragraph: "Allestimento dello stand in fiera"
            }   
        },
        {
            project: {
                id: "7",
                image: '',
                header: "Jaquar Group",
                paragraph: "Volantini: Localizzazione, traduzione in Polacco e stampa"
            }   
        },
        {
            project: {
                id: "8",
                image: '',
                header: "Jaquar Group",
                paragraph: "Sviluppo e design della presentazione corporativa"
            }   
        },
        {
            project: {
                id: "9",
                image: '',
                header: "Caffetteria Byblos",
                paragraph: "Design di menù board"
            }   
        },
        {
            project: {
                id: "10",
                image: '',
                header: "Caffetteria Byblos",
                paragraph: "Stile corporativo e design del menù"
            }   
        },
        {
            project: {
                id: "11",
                image: '',
                header: "Caffetteria Byblos",
                paragraph: "Supporto sui social media"
            }   
        },
        {
            project: {
                id: "12",
                image: '',
                header: "Jaquar Group",
                paragraph: "Brochure: localizzazione per il mercato polacco e stampa"
            }   
        },
        {
            project: {
                id: "13",
                image: '',
                header: "Jaquar Group",
                paragraph: "Catalogo: localizzazione per il mercato polacco e stampa"
            }   
        },
        {
            project: {
                id: "14",
                image: '',
                header: "Jaquar Group",
                paragraph: "Design dei poster "
            }   
        }
    ],
    cookiesBannerText: {
        greetings: "Saluti! Utilizziamo cookie per offrire un servizio migliore.",
        agreeButton: "Accetto"
    }, 

    cookiesPolicy: {
        privacyPolicy: {
            pageHeader: "Informativa sul trattamento dei dati personali",
            header: "PRIVACY POLICY",
            policyItems: [
                {
                    id: "1",
                    content: "La presente Privacy Policy è stata elaborata  ai sensi del Regolamento UE n. 2016/679 del 27 aprile 2016 (in seguito, “GDPR”) e determina il modo del trattamento dei dati personali raccolti durante la registrazione sul sito per garantire la sicurezza di questi dati da parte di Daria El-Smaili che opera al seguente indirizzo: Franciszka Klimczaka 17/80, 02-797 Varsavia, Polonia, numero di identificazione fiscale (NIP): 9512486306, in qualità dell’Amministratore dei dati personali (in seguito “Amministratore”)."
                },
                {
                    id: "2",
                    content: "I Vostri dati saranno trattati dall’Amministratore con le modalità e per le finalità seguenti: invio delle newsletter e dei messaggi con informazioni commerciali; contatto con l’Amministratore tramite moduli di contatto; difesa dalle possibili accuse sia analitiche sia statistiche."
                },
                {
                    id: "3",
                    content: "I dati saranno trasferiti alle entità che li elaboreranno per nostro conto sulla base di accordi con l'Amministratore, ma solo per lo scopo e l'ambito necessari per attuare gli obiettivi sopra menzionati, comprese le entità che ci forniscono servizi IT o altri servizi che garantiscono il corretto funzionamento del sito web smaili.pl, dove tali entità elaborano i dati solo in conformità con le istruzioni dell'amministratore."
                },
                {
                    id: "4",
                    content: "Oltre a tali entità, i dati potranno essere messi a disposizione solo alle entità autorizzate a farlo ai sensi della normativa nazionale o quella della EU. L'Amministratore del sito web ha esercitato la dovuta diligenza al fine di proteggere adeguatamente i dati personali forniti, e in particolare contro la divulgazione a persone non autorizzate. "
                },
                {
                    id: "5",
                    content: "I dati personali saranno trattati solo per il tempo necessario al raggiungimento della finalità del trattamento o fino alla revoca del consenso. L'Amministratore si riserva il diritto di trattare i dati per un periodo massimo di 3 mesi dal raggiungimento degli obiettivi in connessione con l'attuazione dei propri obiettivi analitici e statistici."
                },
                {
                    id: "6",
                    description: "I dati personali potranno essere trattati sulla base di:",
                    content: [
                        {
                            id: "a",
                            text: "consenso (Art. 6 (1) (a) del GDPR), in particolare per scopi di marketing ;"
                        },
                        {
                            id: "b",
                            text: "adempimento dell'obbligo derivante dalle disposizioni di legge sia nazionali che comunitarie ai sensi del art. 6 par. 1 lit. c GDPR;"
                        },
                        {
                            id: "c",
                            text: "per scopi derivanti da interessi legittimi perseguiti dall'Amministratore, inclusa la fornitura di risposte alle domande inviate tramite il modulo di contatto e per scopi statistici e analitici dell'Amministratore, dove l'interesse giustificato dell'Amministratore è la possibilità di analisi e statistiche delle azioni svolte da lui secondo all'art. 6 par. 1 punto f) del GDP, nonché allo scopo di difendersi da qualsiasi pretesa sulla base del legittimo interesse dell'Amministratore, laddove l'interesse giustificato dell'Amministratore sia quello di difendere l'Amministratore da pretese di terzi."
                        }
                    ]
                },
                {
                    id: "7",
                    content: "In ogni momento è possibile revocare i consensi espressi nel sito, compreso il consenso al trattamento dei dati per finalità di marketing dei soggetti che collaborano con l'Amministratore. La revoca del consenso al trattamento non pregiudica la liceità del trattamento effettuato prima della revoca."
                },
                {
                    id: "8",
                    content: "Nella Vostra qualità di interessati, avete i diritti di richiedere l'accesso ai dati, la rettificazione, la cancellazione, il trasferimento o la limitazione del trattamento dei vostri dati, nonché il diritto di opporvi al trattamento. In caso voleste godere dei vostri diritti, potete inviarci un messaggio a: info@smaili.pl."
                },
                {
                    id: "9",
                    content: "La sicurezza dei dati personali è la nostra priorità, tuttavia, se ritenete che trattando i vostri dati personali, violiamo le disposizioni del GDPR, avete il diritto di presentare un reclamo al Presidente dell'Ufficio per la protezione dei dati personali."
                }
            ]
        },
        cookiesPolicy: {
            header: "COOKIES POLICY",
            description: "La seguente Politica sui cookie stabilisce le regole per il salvataggio e l'accesso ai dati sui dispositivi degli utenti che utilizzano il sito Web allo scopo di fornire servizi elettronicamente dall'amministratore del sito Web.",
            policyParagraphs: [
                {
                    id: "1",
                    header: "§ 1 DEFINIZIONI",
                    paragraphItems: [
                        {
                            id: "1.0",
                            text: "Веб- сайт – веб-страница, расположенная в сети Интернет по адресу: https://smaili.pl/"
                        },
                        {
                            id: "1.1",
                            text: "Servizio esterno - servizio Internet eseguito da partner, fornitori di servizi o destinatari di servizi dell'Amministratore."
                        },
                        {
                            id: "1.2",
                            text: "Amministratore - Daria El-Smaili, che opera al seguente indirizzo: Franciszka Klimczaka 17/80, 02-797 Varsavia, Polonia, con un numero di identificazione fiscale (NIP): 9512486306, fornisce servizi elettronicamente tramite il sito Web, memorizza e accede alle informazioni nei dispositivi degli Utenti."
                        },
                        {
                            id: "1.3",
                            text: "Utente - una persona fisica per la quale l'amministratore fornisce servizi elettronicamente tramite il sito web."
                        },
                        {
                            id: "1.4",
                            text: "Dispositivo - un dispositivo elettronico con un software tramite il quale l'Utente accede al Sito Web."
                        },
                        {
                            id: "1.5",
                            text: "Cookie - dati di testo raccolti sotto forma di file posizionati sul dispositivo dell'utente."
                        },
                    ]
                },
                {
                    id: "2",
                    header: "§ 2 TIPI DI COOKIES”",
                    paragraphItems: [
                        {
                            id: "2.1",
                            text: "Cookie interni - i file caricati e letti dal dispositivo dell'utente che vengono inviati al sistema ICT del sito Web."
                        },
                        {
                            id: "2.2",
                            text: "Cookie esterni - file inseriti e letti dal dispositivo dell'utente con i sistemi di teleinformazione dei Servizi Esterni."
                        },
                        {
                            id: "2.3",
                            text: "Cookie di sessione - file caricati e letti dal dispositivo dell'Utente dal sito Web o da Servizi Esterni durante una sessione di un determinato dispositivo. Al termine della sessione, i file vengono eliminati dal dispositivo dell'Utente."
                        },
                        {
                            id: "2.4",
                            text: "Cookie persistenti - file caricati e letti dal dispositivo dell'Utente dal sito Web o da Servizi Esterni fino a quando non vengono rimossi manualmente. I file non vengono eliminati automaticamente dopo la fine della sessione del dispositivo a meno che la configurazione del dispositivo dell'utente non sia impostata sulla modalità di eliminazione dei cookie al termine della sessione del dispositivo."
                        },
                    ]
                },
                {
                    id: "3",
                    header: "§ 3 SICUREZZA",
                    paragraphItems: [
                        {
                            id: "3.1",
                            text: "Meccanismi di memorizzazione e lettura - Meccanismi di memorizzazione e lettura dei cookie non consentono il download di dati personali o informazioni riservate dal Dispositivo dell'Utente. Non è possibile trasferire virus, Trojan o altri worm sul dispositivo dell'Utente."
                        },
                        {
                            id: "3.2",
                            text: "Cookie interni - i cookie interni utilizzati dall'Amministratore sono sicuri per i Dispositivi degli Utenti."
                        },
                        {
                            id: "3.3",
                            text: "Cookie esterni - per la sicurezza dei file cookie dei partner l'Amministratore del sito web non è responsabile. L'elenco dei partner è fornito ulteriormente nella Cookie Policy."
                        },
                    ]
                },
                {
                    id: "4",
                    header: "§ 4 OBIETTIVI A CUI VENGONO UTILIZZATI I COOKIE",
                    paragraphItems:[
                        {
                            id: "4.1",
                            text: "Razionalizzazione e facilitazione dell'accesso al sito Web - L'amministratore può memorizzare informazioni  cookie sulle preferenze e le impostazioni dell'utente relative al sito Web per migliorare, migliorare e velocizzare la fornitura di servizi sul sito Web."
                        },
                        {
                            id: "4.2",
                            text: "Accesso - l'Amministratore utilizza i cookie allo scopo di fare gli utenti eseguire l’accesso sul sito Web."
                        },
                        {
                            id: "4.3",
                            text: "Marketing e pubblicità - l'Amministratore e i siti web esterni utilizzano i cookie per scopi di marketing e per fornire annunci pubblicitari degli utenti."
                        },
                        {
                            id: "4.4",
                            text: "Dati statistici - l'Amministratore e i siti Web esterni utilizzano i file cookie per raccogliere ed elaborare dati statistici, come, ad esempio, statistiche sulle visite, statistiche sui dispositivi dell'utente o statistiche sul comportamento degli utenti. Questi dati vengono raccolti per analizzare e migliorare il sito web."
                        },
                        {
                            id: "4.5",
                            text: "Fornitura di servizi multimediali - l'Amministratore e i servizi esterni utilizzano i file cookie per servire gli utenti dei servizi multimediali."
                        },
                        {
                            id: "4.6",
                            text: "Servizi sociali - l'Amministratore e i siti web esterni utilizzano i cookie per supportare i servizi sociali."
                        },
                    ]
                },
                {
                    id: "5",
                    header: "§ 5 SITI WEB ESTERNI",
                    description: "L'Amministratore collabora con i seguenti siti Web esterni, che possono inserire file cookie sui dispositivi dell'utente:",
                    paragraphItems: [
                        {
                            id: "5.1",
                            text: "Google Analytics"
                        },
                        {
                            id: "5.2",
                            text: "Google AdSense"
                        },
                        {
                            id: "5.3",
                            text: "Google AdWords"
                        },
                        {
                            id: "5.4",
                            text: "Facebook"
                        },
                        {
                            id: "5.5",
                            text: "Youtube"
                        }
                    ]
                },
                {
                    id: "6",
                    header: "§ 6 LE POSSIBILITÀ DI DETERMINARE LE CONDIZIONI DI MEMORIZZAZIONE E DI ACCESSO AI DISPOSITIVI DELL'UTENTE TRAMITE SITO WEB E SERVIZI ESTERNI",
                    paragraphItems: [
                        {
                            id: "6.1",
                            text: "L'Utente può, in qualsiasi momento, modificare autonomamente le impostazioni per il salvataggio, l'eliminazione e l'accesso ai file Cookie memorizzati."
                        },
                        {
                            id: "6.2",
                            text: "Le informazioni su come disabilitare i cookie nei browser di computer e dispositivi mobili più diffusi sono disponibili alla pagina: come disabilitare i cookie https://www.aboutcookies.org/how-to-delete-cookies/."
                        },
                        {
                            id: "6.3",
                            text: "L'Utente può in qualsiasi momento cancellare qualsiasi file Cookie salvato fino alla data odierna utilizzando gli strumenti del Dispositivo dell'Utente tramite i quali l'Utente utilizza i servizi del Sito."
                        }
                    ]
                },
                {
                    id: "7",
                    header: "§ 7 REQUISITI DEL SITO WEB",
                    paragraphItems: [
                        {
                            id: "7.1",
                            text: "Limitando la registrazione e l'accesso ai file cookie sul Dispositivo dell'Utente si può causare il malfunzionamento di alcune delle funzioni del Sito Web."
                        },
                        {
                            id: "7.2",
                            text: "L'Amministratore non si assume alcuna responsabilità per il malfunzionamento delle funzioni del Sito se l'Utente limita in qualche modo la possibilità di salvare e leggere i cookie."
                        }
                    ]
                },
                {
                    id: "8",
                    header: "§ 8 MODIFICHE ALL'INFORMATIVA SUI COOKIE",
                    paragraphItems: [
                        {
                            id: "8.1",
                            text: "L'Amministratore si riserva il diritto di modificare la presente Cookie Policy senza necessità di informare gli utenti."
                        },
                        {
                            id: "8.2",
                            text: "Le modifiche della Cookie Policy su utilizzo dei cookie saranno sempre pubblicate in questa pagina."
                        },
                        {
                            id: "8.3",
                            text: "Le modifiche apportate entrano in vigore il giorno della pubblicazione dalla Cookie Policy."
                        }
                    ]
                }
            ],
        }
    }
}
